.swipe-area {
  position: relative;
  width: 300px;
  height: 40px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 1px #a8dadc;
}

.swipe-fill {
  position: absolute;
  height: 100%;
  background-color: #a8dadc;
  top: 0;
  left: 0;
  width: 0;
  transition: width 0s ease;
}

.draggable {
  position: absolute;
  width: 60px;
  height: 40px;
  background-color: #455a64;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.text-container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  white-space: pre-wrap;
  font-size: 18px;
  font-weight: bold;
}

.text-container span {
  position: relative;
  display: inline;
  transition: color 0s ease;
  margin: 0; /* Boşlukları kaldırmak için */
  padding: 0; /* Boşlukları kaldırmak için */
  letter-spacing: normal; /* Boşlukların düzeltildiğinden emin olun */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.swipe-image-container {
  animation: fadeIn 0.8s ease-in-out;
}
